import React from 'react';
import { ButtonOptionPicker, PseudoLink2 } from '../../../toolympus/components/primitives';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { SimpleList2 } from '../../../toolympus/components/primitives/SimpleList2';
import { useCalendarEvents } from './useCalendarEvents';
import { EditCalendarEventDialog } from './EditCalendarEventDialog';
import { PluggableDocumentationButton } from '../../../toolympus/components/Documentation';
import moment from 'moment';
import { useFormats } from '../../../toolympus/components/schemed';

interface Props {
  
}

export const EventsCalendarPage = (props: Props) => {
  const data = useCalendarEvents();
  const formats = useFormats();
  return (
    <>
      
      <SimpleList2
        title="Календарь"
        data={!data.filter.showPast
          ? {
            ...data,
            view: "",
            setView: () => {},
            filter: data.filter.filter,
            setFilter: data.filter.setFilter,
            create: () => {
              data.newItem.startEditing();
            }
          }
          : {
            ...data.past,
            filter: data.filter.filter,
            setFilter: data.filter.setFilter,
          }}
        tableProps={data.filter.showPast ? {
          tailAnchor: data.past.limitTrigger.pagingAnchor,
        } : undefined}
        fields={[
          ["title"],
          ["casenbr"],
          ["conf_room_id"],
          ["start_date", { label: "Дата" }],
          ["start_datetime"],
          ["end_datetime"],
          ["confirmation"],
        ]}
        fieldElement={f => {
          switch(f) {
            case "title":
              return (r,s,o) => <PseudoLink2 onClick={() => data.editItem.startEditing({ ...r })}>{o}</PseudoLink2>;
            case "start_date":
              return (r) => formats.formatDate(r.start_datetime);
            case "start_datetime":
              return r => moment(r.start_datetime).format("HH:mm");
            case "end_datetime":
              return (r,s,o) => {
                const start = moment(r.start_datetime);
                const end = moment(r.end_datetime);
                if(start.isSame(end, "date")) {
                  return end.format("HH:mm")
                } else {
                  return `${end.format("HH:mm")} ! ${formats.formatDate(r.end_datetime)}`
                }
              }
          }
        }}
        schema={data.schema}
        headerActionsL={<>
          <ButtonOptionPicker
            options={[
              ["мои", "my"],
              ["все", "all"],
            ]}
            selected={data.filter.myOnly ? "my" : "all"}
            setSelected={s => data.filter.setMyOnly(s === "my" ? true : false)}
            />

          <ButtonOptionPicker
            options={[
              ["предстоящие", "future"],
              ["прошедшие", "past"],
            ]}
            selected={data.filter.showPast ? "past" : "future"}
            setSelected={s => data.filter.setShowPast(s === "past" ? true : false)}
            />
        </>}
        
        headerActionsR={<>
          <Link to="/org/conf-rooms">
            <Button size="small" color="primary">
              переговорки
            </Button>
          </Link>
          <PluggableDocumentationButton documentationKey="org_events" />
        </>}
        />

      <EditCalendarEventDialog
        data={data.newItem}
        schema={data.schema}
        />
      
      <EditCalendarEventDialog
        data={data.editItem}
        schema={data.schema}
        />
    </>
  );
}
