import React from 'react';
import { EditItemProps, NewItemProps } from '../../../toolympus/api/useNewItem';
import { Buttons, FormGrid, SimpleDialog } from '../../../toolympus/components/primitives';
import { Schema } from '../../../toolympus/hooks/useSchema';
import { CalendarEvent, DurationOptions } from './useCalendarEvents';
import { PowerEditorFormControl } from '../../../toolympus/components/PowerDoc/slate/PowerEditorFormControl';
import { useItemWithControls } from '../../../toolympus/api/useWithControls';
import { Chip } from '@mui/material';
import { CaseSelect } from '../../Cases/CaseSelect';
import { InsetPanel } from '../../Common/UI';

interface Props {
  data: EditItemProps<CalendarEvent> | NewItemProps<Partial<CalendarEvent>, CalendarEvent>;
  schema: Schema;
}

export const EditCalendarEventDialog = (props: Props) => {
  const { data } = props;

  const { controls } = useItemWithControls({ data: data.item || {}, update: data.update, errors: data.errors }, { schema: props.schema });

  return (
    <SimpleDialog
      dialogTitle="Событие"
      noFullscreen
      submitOnModEnter
      maxWidth="md"
      fullWidth
      isOpen={data.isEditing}
      close={() => data.cancel()}
      save={() => data.save()}>
      {!!data.item &&
        <FormGrid columns="1fr">
          <FormGrid noMargin columns="3fr 200px">
            {controls([
              ["title", { controlProps: { autoFocus: true }}],
            ])}
            <CaseSelect
              field="case_id"
              schema={props.schema}
              setCaseId={id => data.update({ case_id: id })}
              case_id={data.item?.case_id}
              noArea
              />
            <div />
            {controls([
              ["confirmation"],
            ])}
          </FormGrid>


            <FormGrid columns="3fr 3fr 200px" alignItems="center">
              {controls([
                ["start_datetime"],
                ["end_datetime", {
                  readOnly: data.item?.duration_option === DurationOptions.full_day || data.item?.duration_option === DurationOptions.till_day_end,
                }],
              ])}

              <Buttons>
                <Chip
                  size="small"
                  color={data.item?.duration_option === DurationOptions.full_day ? "primary" : "default"}
                  variant={data.item?.duration_option === DurationOptions.full_day ? "filled" : "outlined"}
                  label="весь день"
                  onClick={() => data.update({ duration_option: data.item?.duration_option === DurationOptions.full_day ? DurationOptions.start_end : DurationOptions.full_day})}
                  />
              </Buttons>
            </FormGrid>

            <FormGrid columns="1fr 1fr" alignItems="flex-end">
              {controls([
                ["conf_room_id", { autoComplete: true }],
                ["equipment"],
              ])}
            </FormGrid>
            <InsetPanel>

          {controls([
            ["owners"],
            ["description", { control: PowerEditorFormControl }],
            ["permissions_requests", { control: PowerEditorFormControl }],
          ])}
          </InsetPanel>
        </FormGrid>}
    </SimpleDialog>
  );
}



// :case_id 
// :confirmation 
// :conf_room_id 