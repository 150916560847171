import { useMemo, useState } from "react";
import { useNewItem, useEditItem2, withActionOnSaveItem } from "../../../toolympus/api/useNewItem";
import { useChunkedLoadedList, useLoadedList } from "../../../toolympus/hooks/useLoadedList";
import { mergeSchema, useSingleSchema } from "../../../toolympus/hooks/useSchema";
import { useThrottledCapture } from "../../../toolympus/components/primitives";
import { useDictionaries } from "../../../toolympus/hooks/useDictionaries";
import { useUser } from "../../../toolympus/userContext/UserContext";
import { useConfRoomsSelection } from "./useConfRoomsConfiguration";
import moment, { MomentInput } from "moment";
import { DateTimeInternalFormat } from "../../../toolympus/api/datetimeUtil";

export interface CalendarEvent {
  _id: number;
  title: string;
  description?: any;
  start_datetime: string;
  end_datetime: string;
  duration_option?: string;
  owners?: string[];
  equipment?: string[];
  case_id?: string | null;
}

const ApiPath = "/api/organization/calendar/event";

export const useMyEmployeeId = () => {
  const dicts = useDictionaries();
  const employeesDict = dicts["Employees"];
  const { user } = useUser();

  return employeesDict?.records?.find(e => e.extra?.user_id === user?._id)?.code;
}

const dateStart = (d: MomentInput) => {
  return moment(d).set("hour", 0).set("minutes", 0).set("seconds", 0).format(DateTimeInternalFormat);
}
const dateEnd = (d: MomentInput) => {
  return moment(d).set("hour", 23).set("minutes", 59).set("seconds", 59).format(DateTimeInternalFormat);
}

export const DurationOptions = {
  start_end: "start_end",
  till_day_end: "till_day_end",
  full_day: "full_day",
}

const processEventChanges = (item: Partial<CalendarEvent>, changes: Partial<CalendarEvent>): Partial<CalendarEvent> => {
  let result = changes;
  const newStartS = changes.start_datetime && changes.start_datetime !== "Invalid date" ? changes.start_datetime : null;
  if(newStartS) {
    const endS = changes.end_datetime || item.end_datetime;
    const end = endS ? moment(endS) : null;
    const start = moment(newStartS);
    if(!end) {
      result = {
        ...result,
        end_datetime: start.add(1, "hour").format(DateTimeInternalFormat),
      }
    } else {
      const oldStartS = item.start_datetime;
      const oldStart = oldStartS ? moment(oldStartS) : null;

      if(oldStart && !changes.end_datetime) {
        const oldDuration = moment(item.end_datetime).diff(oldStart);

        result = {
          ...result,
          end_datetime: start.add(oldDuration).format(DateTimeInternalFormat),
        }
      } else {
        if(end.isSameOrBefore(start)) {
          result = {
            ...result,
            end_datetime: start.add(1, "hour").format(DateTimeInternalFormat),
          }
        }
      }
    }
  }

  if(changes.duration_option || newStartS) {
    const start = newStartS || item.start_datetime;
    const duration = changes.duration_option || item.duration_option;
    if(start && duration) {
      if(duration === DurationOptions.till_day_end) {
        result = {
          ...result,
          end_datetime: dateEnd(start),
        }
      } else if(duration === DurationOptions.full_day) {
        result = {
          ...result,
          start_datetime: dateStart(start),
          end_datetime: dateEnd(start),
        }
      }
    }
  }
  return result;
}

export const useCalendarEvents = () => {
  const [filter, setFilter] = useState<string>("");
  const appliedFilter = useThrottledCapture(filter);

  const myEmployeeId = useMyEmployeeId();

  const [myOnly, setMyOnly] = useState<boolean>(false);
  const [showPast, setShowPast] = useState<boolean>(false);

  const data = useLoadedList<CalendarEvent>(ApiPath, {
    noView: true,
    extraParams: {
      search: appliedFilter,
      owner_id: myOnly ? myEmployeeId : "",
    },
  })
  const { schema: schemaBase } = useSingleSchema(`${ApiPath}/uiconfig`);

  const confRooms = useConfRoomsSelection();

  const schema = useMemo(() => {
    return mergeSchema(schemaBase, { conf_room_id: confRooms.selectorSchema });
  }, [schemaBase, confRooms.selectorSchema])

  const newItem = useNewItem<Partial<CalendarEvent>, CalendarEvent>(ApiPath, {
    title: "",
    duration_option: "start_end",
    owners: myEmployeeId ? [myEmployeeId] : [],
    equipment: [],
  }, {
    onChange: processEventChanges,
  });

  const editItem = useEditItem2<CalendarEvent>({
    getApiPath: r => `${ApiPath}/${r._id}`,
    onChange: processEventChanges,
  });

  const past = useChunkedLoadedList<CalendarEvent>(ApiPath, {
    noView: true,
    extraParams: {
      search: appliedFilter,
      owner_id: myOnly ? myEmployeeId : "",
      past: "only",
    },
    noLoad: !showPast,
    sorting: {
      defaultSort: { field: "start_datetime", direction: "desc" },
    }
  });

  return {
    ...data,
    filter: {
      filter,
      setFilter,
      myOnly,
      setMyOnly,
      showPast,
      setShowPast,
    },
    past,
    schema,
    newItem: withActionOnSaveItem(newItem, () => { data.reload(); }),
    editItem: withActionOnSaveItem(editItem, () => { data.reload(); }),
  }
}
